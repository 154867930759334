exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-hokitika-js": () => import("./../../../src/pages/about-hokitika.js" /* webpackChunkName: "component---src-pages-about-hokitika-js" */),
  "component---src-pages-accomodation-js": () => import("./../../../src/pages/accomodation.js" /* webpackChunkName: "component---src-pages-accomodation-js" */),
  "component---src-pages-booking-form-success-js": () => import("./../../../src/pages/booking-form-success.js" /* webpackChunkName: "component---src-pages-booking-form-success-js" */),
  "component---src-pages-food-and-attractions-js": () => import("./../../../src/pages/food-and-attractions.js" /* webpackChunkName: "component---src-pages-food-and-attractions-js" */),
  "component---src-pages-form-success-js": () => import("./../../../src/pages/form-success.js" /* webpackChunkName: "component---src-pages-form-success-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-support-js": () => import("./../../../src/pages/support.js" /* webpackChunkName: "component---src-pages-support-js" */),
  "component---src-pages-terms-and-conditions-js": () => import("./../../../src/pages/terms-and-conditions.js" /* webpackChunkName: "component---src-pages-terms-and-conditions-js" */),
  "component---src-templates-property-template-js": () => import("./../../../src/templates/property-template.js" /* webpackChunkName: "component---src-templates-property-template-js" */)
}

